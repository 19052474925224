import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import {
    CardContainerDPC,
    CardDPC,
    PageContainerDPC,
    CardHorizontalContainerDPC,
    CardHorizontalDPC,
    AreeTematicheDPC,
    LinkUtiliDPC,
    GalleryPhotoVideoContainerDPC,
    GalleryPhotoVideoDPC,
    AllertaMeteoDPC, BannerCarouselContainerDPC,
    AgendaDPC
} from '@dpc-fe/shared';
import {getSlug, prop, getCategoriaPrimariaEN} from "../functions/functions";
import moment from "moment";
import Marquee from "react-fast-marquee";

export default ({data}) => {

    const [strillo, setStrillo] = useState(null);

    const [strilloBarra, setStrilloBarra] = useState(null);

    const showStrillo = strilloBarra && Object.keys(strilloBarra).filter(chiave => 
      !chiave.includes("it-alert") && strilloBarra[chiave].active === "true").length > 0

    const language = '/en';
    const checkData = data.node && data.node.relationships
    const sezioneInEvidenza = checkData && data.node.relationships.field_evidenza_1
    const sezioneInEvidenza2 = checkData && data.node.relationships.field_evidenza_2
    const sezioneInEvidenza3 = checkData && data.node.relationships.field_evidenza_3

    const allertamento = checkData && data.node.relationships.field_allertamento

    const primoPianoContenuto = checkData && data.node.relationships.field_primo_piano_contenuto;
    const primoPianoImage = checkData && primoPianoContenuto && primoPianoContenuto.relationships.field_immagine_anteprima ? primoPianoContenuto.relationships.field_immagine_anteprima : null;
    const primoPianoStreaming = checkData && data.node.relationships.field_primo_piano_streaming;
    const linkUtiliArray = checkData && data.node.relationships.field_link_utili_1.concat(data.node.relationships.field_link_utili_2, data.node.relationships.field_link_utili_3);
    const linkUtili = Array.isArray(linkUtiliArray) ? linkUtiliArray.map(item => {
        if (item.field_link_esterno) {
            return {
                slug: item.field_link.uri,
                titleLinkEsterno: "Vai al sito " + item.field_link.uri,
                title: item.field_titolo_link ? item.field_titolo_link : item.field_link.title
            }
        } else if (!item.field_link_esterno && item.relationships.field_link_interno) {
            return {
                slug: getSlug(language + (item.relationships.field_link_interno.fields ? item.relationships.field_link_interno.fields.slug : ''), process.env.PORTALE, item.relationships.field_link_interno.relationships.field_sottodominio.name),                title: item.field_titolo_link ? item.field_titolo_link : item.relationships.field_link_interno.title
            }
        }
    }) : [];
    const banners = checkData ? data.node.relationships.field_banner.map(item => {
        if (item.field_link_esterno) {
            return {
                slug: item.field_link.uri,
                title: item.field_titolo_link ? item.field_titolo_link : item.field_link.title,
                fluid: item.relationships.field_immagine.localFile?.childImageSharp.fluid,
                alt: item.field_immagine.alt
            }
        } else if (!item.field_link_esterno && item.relationships.field_link_interno) {
            return {
                slug: language + (item.relationships.field_link_interno.fields ? item.relationships.field_link_interno.fields.slug : ''),
                title: item.title,
                fluid: item.relationships.field_immagine.localFile?.childImageSharp.fluid,
                alt: item.field_immagine.alt
            }
        } else {
            return {
                slug: '',
                title: item.title,
                fluid: item.relationships.field_immagine.localFile?.childImageSharp.fluid,
                alt: item.field_immagine.alt
            }
        }
    }) : [];

    const areeTematiche = data.node.relationships.field_aree_tematiche.map(item => {
        return {
            name: item.field_etichetta_en,
            field_url_path: item.field_url_path_en,
            weight: item.weight
        }
    })

    function getStrillo() {
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function() {
          if (this.readyState === 4 && this.status === 200 && this.responseText) {
              var obj=this.responseText;
              try{
                var obj1 = JSON.parse(obj);
                setStrillo(obj1);
              }catch(e){
                console.log('Strillo non presente')
              }
          }
      };
      xhttp.open("GET", '../strillo.en.json', true);
      xhttp.send();

      /*let response = await fetch('../strillo.it.json', {
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          }
      }).catch(() => console.log('Strillo non presente'));

      if (response.ok) {
          let json = await response.json();
          setStrillo(json);
      }*/
  }

    function getStrilloBarra() {
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function() {
          if (this.readyState === 4 && this.status === 200 && this.responseText) {
              var obj=this.responseText;
              try{
                var obj1 = JSON.parse(obj);
                setStrilloBarra(obj1);
              }catch(e){
                console.log('Strillo non presente')
              }
          }
      };
      
      xhttp.open("GET", 'https://dpc-it-alert-headline.s3.eu-south-1.amazonaws.com/dpc-it-alert-headlines.json', true);
      xhttp.send();
  }

    useEffect(() => {
        getStrillo();
        getStrilloBarra();
    }, []);


    return (
        <PageContainerDPC
            menuItems={data.menu.edges}
            isHomepage={true}
            env={process.env.ENV}
            lang={'en'}
            isHomepagePortale={true}
            minisiti={prop}
            menuFooterItems={data.menuFooter.edges}
            basePathNavbar={process.env.PORTALE_URL}
        >

            {showStrillo &&            
              <section className="it-header-strillo">
                  <div className="container-fluid">
                      <div className="it-strillo-wrapper">
                          <div className="px-custom">
                          <Marquee gradient={false} delay={2} speed={90} direction={'left'} pauseOnHover={true} className='marquee'>
                                  {Object.keys(strilloBarra).map(chiave => {
                                      if(!chiave.includes("it-alert") && strilloBarra[chiave].active === "true"){ 
                                      return(                                                                                                                              
                                      <div className= {`message`}  style={{"backgroundColor": `${strilloBarra[chiave].color}`, "width": "100%"}}>
                                          <a href={strilloBarra[chiave].link["en"]}><span
                                                            class="sr-only">Alert</span>{strilloBarra[chiave].message["en"]}</a></div>   
                                      )       
                                      }                                                                            
                                  })}
                          </Marquee>
                          </div>
                      </div>
                  </div>
              </section>
            } 
            {strillo && showStrillo &&
              <div className='separatore-strillo'/>
            }
            {strillo &&
            <section className="alert-strillo">
                <div className="alert-strillo-header">
                    <div className="container">
                        <div className="px-custom">
                            <h2>{strillo.titolo_barra_rossa}</h2>
                        </div>
                    </div>
                </div>
                <div className="alert-strillo-body">
                    <div className="container">
                        <div className="px-custom">
                            <h3>{strillo.titolo_corpo}</h3>
                            <span className="data">{moment(strillo.data).format('D MMMM Y')}</span>
                            <div dangerouslySetInnerHTML={{__html: strillo.corpo}}/>
                        </div>
                    </div>
                </div>
            </section>
            }

            {(primoPianoContenuto && !data.node.field_streaming_homepage) &&
            <CardHorizontalContainerDPC titoloSezione={'Featured'}>
                <CardHorizontalDPC
                    title={primoPianoContenuto.field_titolo_esteso}
                    body={primoPianoContenuto.field_abstract ? primoPianoContenuto.field_abstract.processed : ''}
                    slug={getSlug(language + primoPianoContenuto.fields.slug, 'Portale', primoPianoContenuto.relationships.field_sottodominio.name)}
                    image={primoPianoImage ? primoPianoImage : null}
                    slugText={'Read more'}
                />
            </CardHorizontalContainerDPC>}

            {(primoPianoStreaming && data.node.field_streaming_homepage) &&
            <CardHorizontalContainerDPC titoloSezione={'Featured'}>
                <CardHorizontalDPC
                    title={primoPianoStreaming.field_titolo}
                    body={primoPianoStreaming.field_testo?.value}
                    streaming={primoPianoStreaming}
                />
            </CardHorizontalContainerDPC>}

            {allertamento &&
            <AllertaMeteoDPC title={allertamento.title}
                             statoAllerta={allertamento.field_stato_allerta}
                             body={allertamento.body}
                             linkTitolo={getSlug(language + allertamento.relationships?.field_link_titolo?.fields?.slug, 'Portale', allertamento.relationships?.field_link_titolo?.relationships?.field_sottodominio?.name)}
                             linkBody={getSlug(language + allertamento.relationships?.field_link_testo_esplicativo?.fields?.slug, 'Portale', allertamento.relationships?.field_link_testo_esplicativo?.relationships?.field_sottodominio?.name)}
                             titoloLinkBody={allertamento.relationships?.field_link_testo_esplicativo?.title}
            />
            }

            {(Array.isArray(sezioneInEvidenza) && sezioneInEvidenza.length > 0) &&
            <CardContainerDPC titoloSezione={'Highlights'}>
                {sezioneInEvidenza
                    .slice(0, 3)
                    .map(node => (
                        <div className="col-12 col-md-4">
                            <CardDPC
                                title={node.field_titolo_esteso}
                                body={node.field_abstract ? node.field_abstract.value : ''}
                                slug={getSlug(language + node.fields.slug, 'Portale', node.relationships.field_sottodominio.name)}
                                image={node.relationships.field_immagine_dettaglio}
                                categoria={node.field_categoria_primaria ? getCategoriaPrimariaEN(node.field_categoria_primaria).replace('_', ' ') : ''}
                            />
                        </div>
                    ))}
            </CardContainerDPC>}

            {(Array.isArray(sezioneInEvidenza2) && sezioneInEvidenza2.length > 0) &&
            <CardContainerDPC titoloSezione={''}>
                {sezioneInEvidenza2
                    .slice(0, 3)
                    .map(node => (
                        <div className="col-12 col-md-4">
                            <CardDPC
                                title={node.field_titolo_esteso}
                                body={node.field_abstract ? node.field_abstract.value : ''}
                                slug={getSlug(language + node.fields.slug, 'Portale', node.relationships.field_sottodominio.name)}
                                image={node.relationships.field_immagine_dettaglio}
                                categoria={node.field_categoria_primaria ? getCategoriaPrimariaEN(node.field_categoria_primaria).replace('_', ' ') : ''}
                            />
                        </div>
                    ))}
            </CardContainerDPC>}

            {(Array.isArray(sezioneInEvidenza3) && sezioneInEvidenza3.length > 0) &&
            <CardContainerDPC titoloSezione={''}>
                {sezioneInEvidenza3
                    .slice(0, 3)
                    .map(node => (
                        <div className="col-12 col-md-4">
                            <CardDPC
                                title={node.field_titolo_esteso}
                                body={node.field_abstract ? node.field_abstract.value : ''}
                                slug={getSlug(language + node.fields.slug, 'Portale', node.relationships.field_sottodominio.name)}
                                image={node.relationships.field_immagine_dettaglio}
                                categoria={node.field_categoria_primaria ? getCategoriaPrimariaEN(node.field_categoria_primaria).replace('_', ' ') : ''}
                            />
                        </div>
                    ))}
            </CardContainerDPC>}

            {data?.node?.field_agenda &&
              <AgendaDPC eventi={data?.eventi} locale={"en"} portale={process.env.PORTALE} minisiti={prop}/>
            }
            
            {checkData && Array.isArray(data.node.relationships.field_foto_video) && data.node.relationships.field_foto_video.length > 0 &&
            <GalleryPhotoVideoContainerDPC
                titoloSezione={'Photo and video'}
                linkTorna={data.elencoFotoVideo?.fields && data.elencoFotoVideo.fields.slug ? getSlug(language + data.elencoFotoVideo.fields.slug, 'Portale', data.elencoFotoVideo.relationships.field_sottodominio.name) : null}
            >
                {data.node.relationships.field_foto_video
                    .slice(0, 3)
                    .map(node => (
                        <GalleryPhotoVideoDPC
                            alt={node?.field_alt ? node.field_alt : node?.relationships?.field_immagine_dettaglio?.field_alt}
                            didascalia={node?.field_didascalia ? node.field_didascalia : node?.relationships?.field_immagine_dettaglio?.field_didascalia}
                            path={node.field_categoria_primaria.toLowerCase() === 'video' ? node.field_video?.uri : node.relationships?.field_immagine_dettaglio?.relationships?.image?.localFile?.publicURL}
                            link={getSlug(language + node.fields.slug, 'Portale', node.relationships.field_sottodominio.name)}
                            linkTitle={node.field_titolo_esteso}
                            categoria={node.field_categoria_primaria}/>))}
            </GalleryPhotoVideoContainerDPC>}

            {checkData && data.node.relationships.field_aree_tematiche &&
            <AreeTematicheDPC title={'Thematic areas'} elements={areeTematiche}/>}

            {Array.isArray(linkUtili) && linkUtili.length > 0 &&
            <LinkUtiliDPC titoloSezione={'Useful Links'} linkUtili={linkUtili} classiAggiuntive={"link-utili-hp"}/>
            }

            {banners.length > 0 &&
            <BannerCarouselContainerDPC
                itemsPerPage={4}
                itemsLength={banners.length}
                items={banners}
                lang={language}>
            </BannerCarouselContainerDPC>
            }

        </PageContainerDPC>
    );
}


export const query = graphql`
  {
    node:nodeHomepagePortale(field_codice_lingua: {eq: true}) {
    field_streaming_homepage
    field_agenda
      relationships {
        field_primo_piano_contenuto {
          ...pubblicazioneFragment
          ...notiziaFragment
          ...approfondimentoFragment
          ...normativaFragment
          ...pageFragment
          ...bandoDiGaraFragment
          ...comunicatoStampaFragment
          ...corsoFragment
          ...mappaFragment
          ...timelineFragment
          ...sezioneConAnticipazioniFragment
          ...sezioneSenzaAnticipazioniFragment
          ...elencoApprofondimentiFragment
          ...elencoGlossariFragment
          ...elencoAttualitaFragment
          ...elencoPodcastFragment
          ...elencoPubblicazioniFragment
          ...elencoNormativaFragment
          ...elencoBandiEContrattiFragment
          ...elencoFotoVideoFragment
          ...homepageVolontariatoFragment
          ...homepageRelazioniFragment
          ...homepageRischiFragment
          ...homepageNazionaleFragment
          ...homepageMappeFragment
          ...homepageGiovaniFragment
          ...homepageFormazioneFragment
          ...homepageEventiFragment
          ...homepageEmergenzeFragment
          ...homepageDocsFragment
          ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
...bollettinoCriticitaFragment
...bollettinoVigilanzaFragment
...sezioneAllertaMeteoIdroFragment
        }
        field_primo_piano_streaming {
            field_testo {
              value
            }
            field_link {
              uri
              title
            }
            field_titolo
        }
         field_allertamento {
        title
        field_titolo_esteso
        field_stato_allerta
        body {
          processed
        }
        relationships {
          field_link_testo_esplicativo {
            ... on node__bollettino_criticita {
              id
              fields {
                slug
              }
              title
              relationships {
                field_sottodominio {
                  name
                }
              }
            }
            ... on node__bollettino_vigilanza {
              id
              fields {
                slug
              }
              title
              relationships {
                field_sottodominio {
                  name
                }
              }
            }
            ... on node__sezione_allerta_meteo_idro {
              fields {
                slug
              }
              title
              relationships {
                field_sottodominio {
                  name
                }
              }
            }
            ... on node__sezione_con_anticipazioni {
              fields {
                slug
              }
              title
              relationships {
                field_sottodominio {
                  name
                }
              }
            }
          }
          field_link_titolo {
            ... on node__bollettino_criticita {
              id
              fields {
                slug
              }
              title
              relationships {
                field_sottodominio {
                  name
                }
              }
            }
            ... on node__bollettino_vigilanza {
              id
              fields {
                slug
              }
              title
              relationships {
                field_sottodominio {
                  name
                }
              }
            }
            ... on node__sezione_allerta_meteo_idro {
              fields {
                slug
              }
              title
              relationships {
                field_sottodominio {
                  name
                }
              }
            }
            ... on node__sezione_con_anticipazioni {
              fields {
                slug
              }
              title
              relationships {
                field_sottodominio {
                  name
                }
              }
            }
          }
        }
      }
        field_evidenza_1 {
          ...pubblicazioneFragment
          ...notiziaFragment
          ...approfondimentoFragment
          ...normativaFragment
          ...pageFragment
          ...bandoDiGaraFragment
          ...comunicatoStampaFragment
          ...corsoFragment
          ...mappaFragment
          ...timelineFragment
          ...sezioneConAnticipazioniFragment
          ...sezioneSenzaAnticipazioniFragment
          ...elencoApprofondimentiFragment
          ...elencoGlossariFragment
          ...elencoAttualitaFragment
          ...elencoPodcastFragment
          ...elencoPubblicazioniFragment
          ...elencoNormativaFragment
          ...elencoBandiEContrattiFragment
          ...elencoFotoVideoFragment
          ...homepageVolontariatoFragment
          ...homepageRelazioniFragment
          ...homepageRischiFragment
          ...homepageNazionaleFragment
          ...homepageMappeFragment
          ...homepageGiovaniFragment
          ...homepageFormazioneFragment
          ...homepageEventiFragment
          ...homepageEmergenzeFragment
          ...homepageDocsFragment
          ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
        ...bollettinoCriticitaFragment
        ...bollettinoVigilanzaFragment
        ...sezioneAllertaMeteoIdroFragment
        ...videoFragment
        }
        field_evidenza_2 {
          ...pubblicazioneFragment
          ...notiziaFragment
          ...approfondimentoFragment
          ...normativaFragment
          ...pageFragment
          ...bandoDiGaraFragment
          ...comunicatoStampaFragment
          ...corsoFragment
          ...mappaFragment
          ...timelineFragment
          ...sezioneConAnticipazioniFragment
          ...sezioneSenzaAnticipazioniFragment
          ...elencoApprofondimentiFragment
          ...elencoGlossariFragment
          ...elencoAttualitaFragment
          ...elencoPodcastFragment
          ...elencoPubblicazioniFragment
          ...elencoNormativaFragment
          ...elencoBandiEContrattiFragment
          ...elencoFotoVideoFragment
          ...homepageVolontariatoFragment
          ...homepageRelazioniFragment
          ...homepageRischiFragment
          ...homepageNazionaleFragment
          ...homepageMappeFragment
          ...homepageGiovaniFragment
          ...homepageFormazioneFragment
          ...homepageEventiFragment
          ...homepageEmergenzeFragment
          ...homepageDocsFragment
          ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
...bollettinoCriticitaFragment
...bollettinoVigilanzaFragment
...sezioneAllertaMeteoIdroFragment
...videoFragment
        }
        field_evidenza_3 {
          ...pubblicazioneFragment
          ...notiziaFragment
          ...approfondimentoFragment
          ...normativaFragment
          ...pageFragment
          ...bandoDiGaraFragment
          ...comunicatoStampaFragment
          ...corsoFragment
          ...mappaFragment
          ...timelineFragment
          ...sezioneConAnticipazioniFragment
          ...sezioneSenzaAnticipazioniFragment
          ...elencoApprofondimentiFragment
          ...elencoGlossariFragment
          ...elencoAttualitaFragment
          ...elencoPodcastFragment
          ...elencoPubblicazioniFragment
          ...elencoNormativaFragment
          ...elencoBandiEContrattiFragment
          ...elencoFotoVideoFragment
          ...homepageVolontariatoFragment
          ...homepageRelazioniFragment
          ...homepageRischiFragment
          ...homepageNazionaleFragment
          ...homepageMappeFragment
          ...homepageGiovaniFragment
          ...homepageFormazioneFragment
          ...homepageEventiFragment
          ...homepageEmergenzeFragment
          ...homepageDocsFragment
          ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
...bollettinoCriticitaFragment
...bollettinoVigilanzaFragment
...sezioneAllertaMeteoIdroFragment
...videoFragment
        }
        field_link_utili_1 {
          field_link_esterno
          field_titolo_link
          field_link {
            title
            uri
          }
          relationships {
            field_link_interno {
              ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
...bollettinoCriticitaFragment
...bollettinoVigilanzaFragment
...sezioneAllertaMeteoIdroFragment
            }
          }
        }
        field_link_utili_2 {
          field_link_esterno
          field_titolo_link
          field_link {
            title
            uri
          }
          relationships {
            field_link_interno {
              ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
...bollettinoCriticitaFragment
...bollettinoVigilanzaFragment
...sezioneAllertaMeteoIdroFragment
            }
          }
        }
        field_link_utili_3 {
          field_link_esterno
          field_titolo_link
          field_link {
            title
            uri
          }
          relationships {
            field_link_interno {
              ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
...bollettinoCriticitaFragment
...bollettinoVigilanzaFragment
...sezioneAllertaMeteoIdroFragment
            }
          }
        }
        field_foto_video {
          ...immagineFragment
          ...videoFragment
          ...galleriaFotoFragment
          ...galleriaFlickrFragment
        }
        field_aree_tematiche {
          field_etichetta_en
          field_url_path_en
          weight
        }
        field_banner {
          field_immagine {
              title
              alt
          }
          relationships {
            field_immagine {
              localFile {
                  childImageSharp {
                    fluid(maxWidth: 1100) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                  }
              }
            }
            field_link_interno {
              ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
...bollettinoCriticitaFragment
...bollettinoVigilanzaFragment
...sezioneAllertaMeteoIdroFragment
            }
          }
          field_link_esterno
          title
          field_link {
            title
            uri
          }
        }
      }
    },
            menu: allTaxonomyTermMenuPortaleEn {
          edges {
            node {
              name
              weight
              relationships {
                field_link_menu {
                    ... on node__approfondimento {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__notizia {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__page {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__sezione_senza_anticipazioni {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__sezione_con_anticipazioni {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_approfondimenti {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_podcast {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_notizie_comunicati_stampa {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_glossario {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_pubblicazioni {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_normative {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_bandi_di_gara_e_contratti {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_foto_e_video {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__homepage_minisito_volontariato {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                        drupal_internal__nid
                    }
                    ... on node__homepage_minisito_rischi {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                        drupal_internal__nid
                    }
                    ... on node__homepage_minisito_ser_nazionale {
                        fields {
                                slug
                           }
                           relationships {
                                field_sottodominio {
                                    name
                                }
                           }
                           drupal_internal__nid
                    }
                    ... on node__homepage_minisito_relazioni {
                        fields {
                                slug
                           }
                           relationships {
                                field_sottodominio {
                                    name
                                }
                           }
                           drupal_internal__nid
                    }
                    ... on node__homepage_minisito_mappe {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_scuola_giovani {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_formazione {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_eventi {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_emergenze {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_domande {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_docs {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_pnrr {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_it_alert {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                }
                parent {
                  name
                  drupal_internal__tid
                }
              }
              drupal_internal__tid
            }
          }
        },
    elencoFotoVideo:nodeElencoFotoEVideo (field_codice_lingua: {eq: true}, relationships: {field_sottodominio: {name: {eq: "Portale"}}}) {
      title
      field_titolo_esteso
      fields {
        slug
      }
      relationships {
        field_sottodominio {
          name
        }
      }
    },
    menuFooter: allTaxonomyTermMenuFooterEn {
          edges {
            node {
              name
              weight
              relationships {
                field_link_menu {
                    ... on node__approfondimento {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__page {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_glossario {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                }
              }
              drupal_internal__tid
            }
          }
        }
        eventi: allNodeEventi(
          filter: {
            field_codice_lingua: {eq: true}, relationships: { field_sottodominio: { name: { eq: "Portale" } } }
          }
        ) {
          edges {
            node {
              body {
                processed
              }
              field_link {
                title
                uri
              }
              relationships {
                field_sottodominio {
                  name
                  id
                  drupal_id
                  drupal_internal__tid
                }
                field_link_interno {
                  ...pubblicazioneFragment
                  ...notiziaFragment
                  ...approfondimentoFragment
                  ...normativaFragment
                  ...pageFragment
                  ...bandoDiGaraFragment
                  ...comunicatoStampaFragment
                  ...corsoFragment
                  ...mappaFragment
                  ...timelineFragment
                  ...sezioneConAnticipazioniFragment
                  ...sezioneSenzaAnticipazioniFragment
                  ...elencoApprofondimentiFragment
                  ...elencoGlossariFragment
                  ...elencoAttualitaFragment
                  ...elencoPodcastFragment
                  ...elencoPubblicazioniFragment
                  ...elencoNormativaFragment
                  ...elencoBandiEContrattiFragment
                  ...elencoFotoVideoFragment
                  ...homepageVolontariatoFragment
                  ...homepageRelazioniFragment
                  ...homepageRischiFragment
                  ...homepageNazionaleFragment
                  ...homepageMappeFragment
                  ...homepageGiovaniFragment
                  ...homepageFormazioneFragment
                  ...homepageEventiFragment
                  ...homepageEmergenzeFragment
                  ...homepageDocsFragment
                  ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
                  ...bollettinoCriticitaFragment
                  ...bollettinoVigilanzaFragment
                  ...sezioneAllertaMeteoIdroFragment
                  ...galleriaFotoFragment
                  ...videoFragment
                  ...galleriaFlickrFragment
                }
                field_allegati {
                  title
                  field_titolo_esteso
                  relationships {
                    field_sottodominio {
                      name
                    }
                    field_allegato {
                      filesize
                      filename
                      localFile {
                        publicURL
                        extension
                      }
                    }
                  }
                }
              }
              field_link_esterno
                field_data_evento
                title
              field_titolo_esteso
              field_luogo
            }
          }
        }
  }
`;
